import CryptoJS from 'crypto-js';

const LENGTH_KEYS = {
  key: 32, // 256 bits
  iv: 16   // 128 bits
};

export module EncryptHelper {
  // Generate random bytes and convert to hex string
  const generateRandomBytes = (length: number): string => {
    const randomWords = CryptoJS.lib.WordArray.random(length);
    return CryptoJS.enc.Hex.stringify(randomWords);
  };

  // Encrypt a message
  export const encrypt = (toCypher: string): string => {
    const key = generateRandomBytes(LENGTH_KEYS.key);
    const iv = generateRandomBytes(LENGTH_KEYS.iv);

    const keyBytes = CryptoJS.enc.Hex.parse(key);
    const ivBytes = CryptoJS.enc.Hex.parse(iv);

    // Encrypt the message
    const encrypted = CryptoJS.AES.encrypt(toCypher, keyBytes, { iv: ivBytes });
    
    // Convert encrypted message to hex
    const encryptedMessage = CryptoJS.enc.Hex.stringify(CryptoJS.enc.Base64.parse(encrypted.toString()));

    // Format the result
    const indexedStrings: string[] = [
      key,
      encryptedMessage,
      iv
    ].map((string: string, index: number) => `${string}${index}`);
    return indexedStrings.join(':');
  };

  // Decrypt a message
  export const decrypt = (message: string): string => {
    const [key, encryptedMessage, iv] = unIndexString(message);

    const keyBytes = CryptoJS.enc.Hex.parse(key);
    const ivBytes = CryptoJS.enc.Hex.parse(iv);

    // Convert encrypted message from hex to Base64
    const encryptedBase64 = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Hex.parse(encryptedMessage));

    // Decrypt the message
    const decrypted = CryptoJS.AES.decrypt(encryptedBase64, keyBytes, { iv: ivBytes });
    return decrypted.toString(CryptoJS.enc.Utf8);
  };

  // Helper functions
  const unIndexString = (indexedString: string): string[] => {
    const indexedStrings = indexedString.split(":");
    return indexedStrings.map((string: string) => sliceString(string));
  };

  const sliceString = (str: string): string => str.slice(0, -1);
}
