import { Button, Grid } from "@mui/material";
import { SpringLogger } from "@spring-global/spring-logger-react";
import AppRoutes from "AppRoutes.json";
import AppMenu from "AppMenu.json";
import HeaderV2 from "components/Header/HeaderV2";
import { Icon } from "components/Icon/Icon";
import { LoadingData } from "components/LoadingData/LoadingData";
import useAccessToken from "hooks/useAccessToken";
import GenericList from "pages/GenericList/GenericList";
import { CSSProperties, useEffect, useMemo, useState } from "react";
import { Redirect, Route, RouteProps, useHistory, useLocation } from "react-router-dom";
import "../App.scss";
import { Sidebar } from "../components/Sidebar/Sidebar";
import { useAppDispatch, useAppState } from "../context/StateProvider";
import * as storage from "../context/Storage";
import { AuthenticationResult } from "../models/AuthenticationResult";
import { LoginService } from "../services/LoginService";

type CustomRouteProps = {
  showLeftSidebar: boolean;
  isGenericRoute?: boolean;
  metadata?: any;
  beta?: boolean;
  overlay?: boolean;
}

type PrivateRouteProps = RouteProps & CustomRouteProps;

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {
  const getShowLeftSidebar = () => {
    const query = new URLSearchParams(location.search);
    const embeddedMode = query.get("embeddedMode");
    if (embeddedMode === "true") return false;
    if (embeddedMode === "false") return true;
    return rest.showLeftSidebar;
  }

  const { identity } = useAppState();
  const location = useLocation();
  const [isLoading, setLoading] = useState(true);
  const [canOpenWindow, setCanOpenWindow] = useState(false);
  const [idToken, setIdToken] = useState(JSON.parse(storage.local.getItem("id_token")!));
  const [showLeftSidebar] = useState(getShowLeftSidebar);
  const accessLevel = useMemo<string>(() => AppRoutes.restricted.find(r => r.path === rest.path)?.accessLevel!, [AppRoutes]);
  const { expired, hasAccessLevel, setAccessToken } = useAccessToken(accessLevel);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const done = () => setLoading(false);
  const goHomeHandler = () => history.push('/');

  const [appMenu, setAppMenu] = useState<any[]>(AppMenu);
  const [updateMenu, setUpdateMenu] = useState<boolean>(false);

  const cleanSessionData = () => {
    dispatch({
      type: "SET_IDENTITY",
      identity: {
        id: '',
        code: '',
        isAuthenticated: false,
        customerId: '',
        regionId: undefined,
        idRegionPath: undefined,
        nmDisplay :''
      }
    });
    storage.local.removeItem('accessToken');
    storage.local.removeItem('id_token');
  }


  const checkSSOCookie = async (): Promise<void> => {
    try {
      const hqAppsCookie: string = new URLSearchParams(location.search).get('hqc')!;
      if (!hqAppsCookie) return;
      const response: AuthenticationResult = await LoginService.authenticateSSOUser(hqAppsCookie);
      if (response.statusCode === 200) {
        setIdToken(response.idToken);
        setAccessToken(response.accessToken);
        storage.local.setItem("accessToken", response.accessToken!);
        storage.local.setItem("id_token", JSON.stringify(response.idToken));

        dispatch({
          type: "SET_IDENTITY",
          identity: {
            id: response.idToken.id,
            code: response.idToken.code,
            isAuthenticated: true,
            customerId: response.idToken.customerId,
            tenantId: response.idToken.tenantId,
            regionId: response.idToken.regionId,
            idRegionPath: response.idToken.idRegionPath,
            mSeriesUserID: response.idToken.mSeriesUserID,
            nmDisplay : idToken.nmDisplay
          }
        });
        setCanOpenWindow(true);
      }
    } catch (e) {
      cleanSessionData();
      SpringLogger.error(e);
    }
  };

  useEffect(() => {
    if (expired) {
      done();
      return;
    }

    if (idToken && !expired) {
      setCanOpenWindow(hasAccessLevel());
      dispatch({ type: "SET_IDENTITY", identity: { id: idToken.id, code: idToken.code, isAuthenticated: true, customerId: idToken.customerId, tenantId: idToken.tenantId, regionId: idToken.regionId, idRegionPath: idToken.idRegionPath, mSeriesUserID: idToken.mSeriesUserID, nmDisplay: idToken.nmDisplay } });
      done();
    } else {
      checkSSOCookie().finally(done);
    }
  }, [location]);

  if (!Component) return null;

  const isPanelOverlay: boolean = false;

  const styles: CSSProperties = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: "1rem",
    marginTop: "-140px",
  }

  const handleUpdateBetaOption = (menuItems: any[]) => {
    const prevTmp = JSON.stringify(appMenu);
    setAppMenu(prev => {
      const _new = prev;
      _new.map((item) => {
        const updItem = item;
        const tmp = menuItems.find(x => x.title === item.title);
        if (tmp)
          updItem.enabled = tmp.enabled

        return updItem;
      })
      if (prevTmp !== JSON.stringify(prev)) {
        setUpdateMenu(prev => !prev)
      }
      return _new;
    })
  }

  return isLoading ? (
    <>
      <div className={`AppBody ${isPanelOverlay ? "PanelOverlay" : ""}`}>
        <Grid>
          <Grid item xs={6} className='pageOverlay'>
            <LoadingData animation="border" />
          </Grid>
        </Grid>
      </div>
    </>
  ) : // Show the component only when the user is logged in
    // Otherwise, redirect the user to /login page
    identity && identity?.isAuthenticated ? (
      (!canOpenWindow) ?
        <>
          <HeaderV2 className="AppHeader" updateBetaOption={handleUpdateBetaOption} />
          {showLeftSidebar ? <Sidebar className="AppSidebar" overlay={isPanelOverlay} appMenu={appMenu} updateMenu={updateMenu} /> : null}
          <div className={`AppBody ${isPanelOverlay ? "PanelOverlay" : ""}`}>
            <Grid>
              <Grid item xs={6} className='pageOverlay'>
                <div style={styles}>
                  <Icon name="error" style={{ width: 50, height: 50, marginLeft: 5, color: "#BFBFBF" }} />
                  <h2>403 | Forbidden</h2>
                  <h4>You cannot access this page</h4>
                  <Button variant="contained" onClick={goHomeHandler}>Home</Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </>
        :
        <Route
          {...rest} render={(props) => (
            <>
              <HeaderV2 className="AppHeader" updateBetaOption={handleUpdateBetaOption} />
              {showLeftSidebar ? <Sidebar className="AppSidebar" overlay={isPanelOverlay} appMenu={appMenu} updateMenu={updateMenu} /> : null}
              <div className={`AppBody ${isPanelOverlay ? "PanelOverlay" : ""}`}>
                {rest.isGenericRoute ? <GenericList {...props} listInfo={rest.metadata} beta={rest.beta} /> : <Component {...props} />}
              </div>
            </>
          )}
        />
    ) : ((<Redirect to={{ pathname: "/login", }} />)
    );
};

export default PrivateRoute;

